export default {
    "REGION": "us-east-1",
    "USER_POOL_ID": "us-east-1_DwSYKFN1I",
    "USER_POOL_APP_CLIENT_ID": "3khqam938vda9l5rcv7kf2b4mt",
    "ROOT_API_URL": "https://pv9p01x9oc.execute-api.us-east-1.amazonaws.com/loganc-dev-exchange-sandbox-documents-api-prod",
    "MFA_ISSUER": "Precedent-loganc-dev-exchange-sandbox",
    "S3_DEPLOYMENT_BUCKET": "loganc-dev-exchange-sandbox-precedent-exchange-document-spa",
    "RUM_IDENTITY_POOL_ID": "us-east-1:cd26694b-172d-427f-8aa7-9222b5de290f",
    "RUM_APP_MONITOR_ID": "4049c8d8-a2b2-4acf-a8fc-2ca02ba0aff3",
    "ENV_STAGE": "dev-loganc"
}
